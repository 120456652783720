import * as clientConfigs from './configs';
import { ExpErrorPageComponent } from './error-page.component';
const uiLessBuild = process.env.portal === 'uiless';
let routeOptions: any = {
    clientConfigs,
    errorPageUrl: 'error-page',
    envDataFetchConfig: {
        from: 'CDN',
        options: {
            url: '/assets/js/portal.config.json',
        },
    },
};
let useLatestThemes = false;
if (uiLessBuild) {
    routeOptions = {
        errorPageUrl: 'error-page',
    };
    useLatestThemes = true;
}

export const routing = [
    {
        path: '',
        loadChildren: () => import('./spa-lib-wrapper.module').then((m) => m.getExpSPAWrapperModule(useLatestThemes)),
        data: {
            path: '',
            preload: true,
            options: routeOptions,
        },
    },
    {
        path: 'error-page',
        component: ExpErrorPageComponent,
    },
];

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        const _error = err.originalError || err;
        Sentry.captureException(_error);
        console.error(_error);
    }
}
